import axios from '@/utils/ajax';
import Global from "@/utils/global";

// 友情链接
export const getFriendlinkByOrganizationId = (params)=>{
  return axios.ajax4UserMgt(Global.getParamsKey('BASE_URL5') + '/friendlink/getByOrganizationId','get',params)
}

// 查询站点下剩余的文件大小
export const getOrganizationFileSize = (params)=>{
  return axios.ajax4UserMgt(Global.getParamsKey('BASE_URI')+'/organizationapi/organization/getOrganizationFileSize','get',params)
}
